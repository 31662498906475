import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import { GET_PROFILE_AGENT, CREATE_AGENT_NEW, GET_ROYALTY_SETTING_BY_ID } from "../../../../api/account"
import { FLOD_LIMIT_LIST, HOLDEM_LIMIT_LIST, SIXPLUS_HOLDEMLIMIT_LIST, PLO4_LIMIT_LIST, PLO5_LIMIT_LIST } from "../Component/gameTypeLimit.component"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import { ShareholderObj } from "../Component/dto.component";
import VModal from 'vue-js-modal'
import moment from 'moment';

Vue.use(VModal, { componentName: 'modal' })
Vue.use(VueCookies)

import 'vue-select/dist/vue-select.css';

@Component({
    components: {
        vSelect,
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        Currency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

class Agent extends Vue {
    
    public role = ""
    public profile: any = {}
    public parentRoyaltySetting: any = {} //TODO: specific DATA Type

    private cookieData: any = Vue.$cookies.get("login")
    public displayShow = true

    private flodpIndex = [ "flodOurPercentage", "flodHoldPercentage"]
    private holdempIndex = [ "holdemOurPercentage", "holdemHoldPercentage"]
    private sixholdempIndex = [ "sixholdemOurPercentage", "sixholdemHoldPercentage"]
    private plo4pIndex = [ "plo4OurPercentage", "plo4HoldPercentage"]
    private plo5pIndex = [ "plo5OurPercentage", "plo5HoldPercentage"]
    

    public allPercentageVal = {
        flodOurPercentage: 0,
        flodHoldPercentage: 0,

        holdemOurPercentage: 0,
        holdemHoldPercentage: 0,

        sixholdemOurPercentage: 0,
        sixholdemHoldPercentage: 0,

        plo4OurPercentage: 0,
        plo4HoldPercentage: 0,

        plo5OurPercentage: 0,
        plo5HoldPercentage: 0,
    }
    
    public currencyVal: any = ''
    public creditLimit = '0'

    public dataAgentnew: ShareholderObj = {
        username: '',
        name: '',
        password: '',
        confirmPassword: '',
        email: '',
        phone: '',
        referrer: '',
        remark: '',
        currency: '',
        creditLimit: 0,
        typeBusiness:  'B2B',
        typeAgentAPI: '',

        flodStatus: false,
        flodOurPercentage: 0,
        flodHoldPercentage: 0,
        flodLimit: 0, //WAIT FOR DROP
        flodLimitArr: [],

        holdemStatus: false,
        holdemOurPercentage: 0,
        holdemHoldPercentage: 0,
        holdemLimit: 0, //WAIT FOR DROP
        holdemLimitArr: [],
        holdemBOF: false,

        sixholdemStatus: false,
        sixholdemOurPercentage: 0,
        sixholdemHoldPercentage: 0,
        sixholdemLimit: 0, //WAIT FOR DROP
        sixholdemLimitArr: [],

        plo4Status: false,
        plo4OurPercentage: 0,
        plo4HoldPercentage: 0,
        plo4Limit: 0, //WAIT FOR DROP
        plo4LimitArr: [],

        plo5Status: false,
        plo5OurPercentage: 0,
        plo5HoldPercentage: 0,
        plo5Limit: 0, //WAIT FOR DROP
        plo5LimitArr: []
    }
    
    public flod_limit_list = FLOD_LIMIT_LIST
    public holdem_limit_list = HOLDEM_LIMIT_LIST
    public sixplus_holdemlimit_list = SIXPLUS_HOLDEMLIMIT_LIST
    public plo4_limit_list = PLO4_LIMIT_LIST
    public plo5_limit_list = PLO5_LIMIT_LIST

    public flagDisplayAsPassword = {
        default: true,
        confirm: true
    }

    private royaltySetting: any = []
    private typeBusiness = ''
    private typeAgent = ''
    private typeAgentAPI = ''
    private model: Model;

    public productPercentSelectionMaster = {
        allinorfold: [],
        holdem: [],
        sixplus: [],
        plo4: [],
        plo5: [],
    }

    public disabledInputMiniGame = {
        holdemBOF: true
    }

    public currencyArray: any = []
    constructor() {
        super();
        this.model = new Model();
    }


    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        await this.getRoyaltySetting() 
        await this.getProfile(this.cookieData.realUsername)
        await this.initData()
        await this.setRate()
        this.addRouteName()
        loader.hide()
    }


    public async getRoyaltySetting() {
        this.royaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.royaltySetting = this.royaltySetting[0].royaltySetting
    }

    private async getProfile(username: string): Promise<void> {
        const result = await GET_PROFILE_AGENT(username)
        this.role = this.cookieData.roles
        this.profile = result[0]
        this.parentRoyaltySetting = result[0].royaltySetting.products

        this.typeBusiness = this.profile.typeBusiness
        if (this.role == 'Company') {
            this.typeBusiness = 'B2B'
            this.dataAgentnew['childRole'] = 'Shareholder'
        } else {
            this.dataAgentnew['childRole'] = 'Agent'
            if (this.typeBusiness == 'B2B') {
                this.typeAgent = 'API'
                this.typeAgentAPI = 'Seamless'
            } else {
                this.dataAgentnew['childRole'] = 'Master Agent'
                this.typeAgent = 'Master Agent'
            }
        }
        this.dataAgentnew['parentId'] = result[0].username

        if (this.role == 'Master Agent') {
            this.dataAgentnew['currency'] = result[0].currency
            this.currencyVal = { label: result[0].currency, value: result[0].currency  }
        }

        const hasMiniGameBOF = this.parentRoyaltySetting.holdem.miniGame?.includes("BOF") || false
        if(hasMiniGameBOF) this.disabledInputMiniGame.holdemBOF = false

        return
    }

    public async initData() {
        const currencyUse = process.env.VUE_APP_CURRENCY_USE
        this.currencyArray.push({label:`${currencyUse}`, value:`${currencyUse}`});
        this.dataAgentnew['parentName'] = this.cookieData.realUsername
        this.dataAgentnew['typeBusiness'] = this.typeBusiness
        this.dataAgentnew['typeAgent'] = this.typeAgent
        this.dataAgentnew['typeAgentAPI'] = this.typeAgentAPI
    }

    public findLocalGameTypeVariableName( objName: string){
        const localProductObj = { 
            allinorfold: { 
                status: "flodStatus", 
                ourPercent: "flodOurPercentage", 
                holdPercent: "flodHoldPercentage", 
                limit: "flodLimitArr",
                percentSelection: this.flodpIndex
            },
            holdem: { 
                status: "holdemStatus", 
                ourPercent: "holdemOurPercentage", 
                holdPercent: "holdemHoldPercentage", 
                limit: "holdemLimitArr",
                percentSelection: this.holdempIndex
            },
            sixplus: { 
                status: "sixholdemStatus", 
                ourPercent: "sixholdemOurPercentage", 
                holdPercent: "sixholdemHoldPercentage", 
                limit: "sixholdemLimitArr",
                percentSelection: this.sixholdempIndex
            },
            plo4: { 
                status: "plo4Status", 
                ourPercent: "plo4OurPercentage", 
                holdPercent: "plo4HoldPercentage", 
                limit: "plo4LimitArr",
                percentSelection: this.plo4pIndex
            },
            plo5: { 
                status: "plo5Status", 
                ourPercent: "plo5OurPercentage", 
                holdPercent: "plo5HoldPercentage", 
                limit: "plo5LimitArr",
                percentSelection: this.plo5pIndex
            },
        }

        for (const [key, value] of Object.entries(localProductObj)) {
            if(key == objName){
                return value
            }
        }
        return false
    }

    public async setRate() {
        
        for (const [objName, productSetting] of Object.entries(this.royaltySetting.products)) {            
            const localValName = this.findLocalGameTypeVariableName(objName)
            if(!localValName){
                continue
            }
            
            this.setEachProductSetting(objName, localValName, productSetting)
        }
        
    }

    public setEachProductSetting(objName: string, localValName: any, productSetting: any){
        this.dataAgentnew[localValName.status] = productSetting.enable
        this.dataAgentnew[localValName.limit] = productSetting.limit
        this.productPercentSelectionMaster[objName] = this.createPercentageList(productSetting.rate)
        this.allPercentageVal[localValName.percentSelection[0]] = this.productPercentSelectionMaster[objName][productSetting.rate]
        this.allPercentageVal[localValName.percentSelection[1]] = this.productPercentSelectionMaster[objName][0] 
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public createPercentageList( maxPercent ){
        const arr: any = []
        for (let i = 0; i <= maxPercent; i ++) {
            arr.push({label:`${i}%`, value: i});
        }
        return arr
    }

    public async switchPercentage(event, myPercentage, yourPercentage, objIndex, rateNumberArray) {
        if(event == objIndex[0]){
            this.allPercentageVal[objIndex[0]] = myPercentage
            this.allPercentageVal[objIndex[1]] = rateNumberArray[(rateNumberArray.length-1) - myPercentage.value]
        } else {
            this.allPercentageVal[objIndex[0]] = rateNumberArray[(rateNumberArray.length-1) - yourPercentage.value]
            this.allPercentageVal[objIndex[1]] = yourPercentage
        }
    }

    public toggleShowPassword(name: string) {
        if (name == 'default') {
            this.flagDisplayAsPassword.default = !this.flagDisplayAsPassword.default;
        }
        if (name == 'confirm') {
            this.flagDisplayAsPassword.confirm = !this.flagDisplayAsPassword.confirm;
        }
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public formatNumber(value) {
        const numberCredit = parseFloat(value.replaceAll(",",""))
        return numberCredit.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    public changePercentage(key: string){

        if(key == this.flodpIndex[0] || key == this.flodpIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.flodpIndex[0]],this.allPercentageVal[this.flodpIndex[1]], this.flodpIndex, this.productPercentSelectionMaster.allinorfold)
        }
        else if(key == this.holdempIndex[0] || key == this.holdempIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.holdempIndex[0]],this.allPercentageVal[this.holdempIndex[1]], this.holdempIndex, this.productPercentSelectionMaster.holdem)
        }
        else if(key == this.sixholdempIndex[0] || key == this.sixholdempIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.sixholdempIndex[0]],this.allPercentageVal[this.sixholdempIndex[1]], this.sixholdempIndex, this.productPercentSelectionMaster.sixplus)
        }
        else if(key == this.plo4pIndex[0] || key == this.plo4pIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.plo4pIndex[0]],this.allPercentageVal[this.plo4pIndex[1]], this.plo4pIndex, this.productPercentSelectionMaster.plo4)
        }
        else if(key == this.plo5pIndex[0] || key == this.plo5pIndex[1]){
            this.switchPercentage(key,this.allPercentageVal[this.plo5pIndex[0]],this.allPercentageVal[this.plo5pIndex[1]], this.plo5pIndex, this.productPercentSelectionMaster.plo5)
        }
        
    }

    public setTypeBusiness(type: string){
        this.dataAgentnew.typeBusiness = type
    }

    public changeInputValue(key: string, value: string) {
        this.displayShow = false
        
        if(key == 'creditLimit'){
            this.creditLimit = this.formatNumber(this.creditLimit)
        }

        if(key == 'typeAgent'){

            this.dataAgentnew[key] = value
            if(value == 'API'){
                this.dataAgentnew['childRole'] = 'Agent'
                this.dataAgentnew['typeAgentAPI'] = 'Seamless'
            } 
            else{
                this.dataAgentnew['childRole'] = 'Master Agent'
                this.dataAgentnew['typeAgentAPI'] = ''
            }
        }

        this.displayShow = true
    }

    public setTypeAgentAPI(value: string){
        this.dataAgentnew.typeAgentAPI = value
    }

    public convertToBoolean(myValue: any){
        if(typeof myValue === "string"){
            return (myValue === 'true')
        }
        else{
            return myValue
        }
    }

    public convertPercentage( value: any){
        if(typeof value === "object"){
            return parseInt(value.value)
        }
        else{
            return value
        }
    }

    public normalizeObj(){
        
        this.dataAgentnew.currency = (typeof this.currencyVal === "object") ? this.currencyVal.value : ""
        this.dataAgentnew['creditLimit'] = parseFloat(this.creditLimit.replace(/,/g, ''))

        this.dataAgentnew.flodStatus = this.convertToBoolean(this.dataAgentnew.flodStatus)
        this.dataAgentnew.holdemStatus = this.convertToBoolean(this.dataAgentnew.holdemStatus) 
        this.dataAgentnew.sixholdemStatus = this.convertToBoolean(this.dataAgentnew.sixholdemStatus) 
        this.dataAgentnew.plo4Status = this.convertToBoolean(this.dataAgentnew.plo4Status) 
        this.dataAgentnew.plo5Status = this.convertToBoolean(this.dataAgentnew.plo5Status)
        
        this.dataAgentnew.flodHoldPercentage = this.convertPercentage(this.allPercentageVal.flodHoldPercentage)  
        this.dataAgentnew.flodOurPercentage = this.convertPercentage(this.allPercentageVal.flodOurPercentage) 

        this.dataAgentnew.holdemHoldPercentage = this.convertPercentage(this.allPercentageVal.holdemHoldPercentage) 
        this.dataAgentnew.holdemOurPercentage = this.convertPercentage(this.allPercentageVal.holdemOurPercentage) 

        this.dataAgentnew.sixholdemHoldPercentage = this.convertPercentage(this.allPercentageVal.sixholdemHoldPercentage) 
        this.dataAgentnew.sixholdemOurPercentage = this.convertPercentage(this.allPercentageVal.sixholdemOurPercentage) 

        this.dataAgentnew.plo4HoldPercentage = this.convertPercentage(this.allPercentageVal.plo4HoldPercentage) 
        this.dataAgentnew.plo4OurPercentage = this.convertPercentage(this.allPercentageVal.plo4OurPercentage) 

        this.dataAgentnew.plo5HoldPercentage = this.convertPercentage(this.allPercentageVal.plo5HoldPercentage) 
        this.dataAgentnew.plo5OurPercentage = this.convertPercentage(this.allPercentageVal.plo5OurPercentage) 

        this.dataAgentnew.flodLimitArr?.sort(this.compareTier)
        this.dataAgentnew.holdemLimitArr?.sort(this.compareTier)
        this.dataAgentnew.sixholdemLimitArr?.sort(this.compareTier)
        this.dataAgentnew.plo4LimitArr?.sort(this.compareTier)
        this.dataAgentnew.plo5LimitArr?.sort(this.compareTier)
        
    }

    public sendForm(event: any) {
        event.preventDefault();
        this.normalizeObj()
        this.sendCreateAgent()
    }

    public async sendCreateAgent() {

        if (this.dataAgentnew.password.length < 8) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        if (this.dataAgentnew.password != this.dataAgentnew.confirmPassword) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_BE_SAME').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            
            return;
        }

        if(this.dataAgentnew.currency == ""){
            this.$swal({
                text: this.$t('MANAGEMENT.PLEASE_CHOOSE_CURRENCY').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        const allinOurCond = this.checkCorrectPercent(this.dataAgentnew.flodOurPercentage)
        if(allinOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.ALLIN_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const allinHoldCond = this.checkCorrectPercent(this.dataAgentnew.flodHoldPercentage) 
        if(allinHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.ALLIN_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const holdemOurCond = this.checkCorrectPercent(this.dataAgentnew.holdemOurPercentage) 
        if(holdemOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.HOLDEM_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const holdemHoldCond = this.checkCorrectPercent(this.dataAgentnew.holdemHoldPercentage)
        if(holdemHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.HOLDEM_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const sixholdemOurCond = this.checkCorrectPercent(this.dataAgentnew.sixholdemOurPercentage) 
        if(sixholdemOurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.SIXPLUS_HOLDEM_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const sixholdemHoldCond = this.checkCorrectPercent(this.dataAgentnew.sixholdemHoldPercentage) 
        if(sixholdemHoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.SIXPLUS_HOLDEM_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const plo4OurCond = this.checkCorrectPercent(this.dataAgentnew.plo4OurPercentage)
        if(plo4OurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO4_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }
        
        const plo4HoldCond = this.checkCorrectPercent(this.dataAgentnew.plo4HoldPercentage) 
        if(plo4HoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO4_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const plo5OurCond = this.checkCorrectPercent(this.dataAgentnew.plo5OurPercentage)
        if(plo5OurCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO5_OUR_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const plo5HoldCond = this.checkCorrectPercent(this.dataAgentnew.plo5HoldPercentage) 
        if(plo5HoldCond){
            this.gameTypePercentSwal(this.$t('MANAGEMENT.PLO5_HOLD_PERCENTAGE_IS_INVALID').toString())
            return;
        }

        const resultSwal = await this.$swal({
            title: `${this.$t('MANAGEMENT.WANT_ADD_DATA').toString()}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        })

        if (!resultSwal.isConfirmed) {
            return
        }

        const resultCreateAgent = await CREATE_AGENT_NEW(this.dataAgentnew)

        if (!resultCreateAgent.success) {
            if (resultCreateAgent.error.message.search("duplicate key") != -1) {
                this.$swal({
                    text: `${this.$t('MANAGEMENT.NOT_USE_USERNAME').toString()}`,
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            } else {
                this.$swal({
                    text: resultCreateAgent.error.message,
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                });
            }

            return
        }

        if (this.role == 'Company') {
            this.$swal({
                text: `${this.$t('MANAGEMENT.ADD_SHAREHOLDER_SUCCESS').toString()}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/agent/agent-member-list');
                }
            });
        } else {
            this.$swal({
                text: `${this.$t('MANAGEMENT.ADD_AGENT_SUCCESS').toString()}`,
                icon: "success",
                timer: 3000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/agent/agent-member-list');
                }
            });
        }
        
    }

    public checkCorrectPercent(percent: number): boolean {
        return typeof percent != "number" || percent > 100 || percent < 0
    }

    public gameTypePercentSwal(message: string){
        this.$swal({
            text: message,
            icon: "error",
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
        });
    }

    compareTier(a: string, b: string) {
        const aTier = parseInt(a.split('/')[0])
        const bTier = parseInt(b.split('/')[0])
        return aTier - bTier
    }

}
export default Agent