import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import AgentComponent from './Agent.component.vue';
Vue.use(VueCookies)

@Component({
    components: {
        AgentComponent
    }
})

class AddAgent extends Vue {
    public role = this.$store.state.profile.roles
}
export default AddAgent